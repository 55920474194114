<template>
	<VariantInfoForm
		:variant-info="variantInfo"
	/>
</template>

<script>
import VariantInfoForm from '../components/VariantInfoForm.vue';

export default {
	name: 'VariantInfoCreate',

	components: {
		VariantInfoForm,
	},

	data() {
		return {
			variantInfo: {},
		};
	},
};
</script>
